import BaseService from "./base.service";

class BannerService {
    banner(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('banner', page, params);
    }
    get(id) {
        return BaseService.apiGetRequest('banner/'+id);
    }
    add(banner) {
        return BaseService.apiPostRequest('banner/add', banner);
    }
    delete(banner) {
        return BaseService.apiPostRequest('banner/delete/', banner);
    }
    update(id, banner) {
        return BaseService.apiPostRequest('banner/'+id, banner);
    }

    search(page=null) {
        return BaseService.apiGetRequest('banner/search', page);
    }
}

export default new BannerService();